import * as React from 'react';

export interface RouteObject {
  path: string;
  exact?: boolean;
  name?: string;
  component: any;
}

// below routes will be render with AdminLayout
export const staffRoutes: RouteObject[] = [
  { path: '/router/orders', exact: true, name: 'Order Assignment', component: React.lazy(() => import('./App/pages/OrderAssignmentPage')) },
  {
    path: '/router/orders/assign',
    exact: true,
    name: 'Assignment to User',
    component: React.lazy(() => import('./App/pages/AssignToUserPage')),
  },

  // edit order-edit

  {
    path: '/customer-service/orders',
    exact: true,
    name: 'Customer Orders',
    component: React.lazy(() => import('./App/pages/CustomerServiceOrders')),
  },
  {
    path: '/customer-service/order/1',
    exact: true,
    name: 'Customer Orders',
    // component: React.lazy(() => import('./App/pages/OrderDetail')),
    component: React.lazy(() => import('./App/pages/CustomerService/OrderForm/OrderForm')),
  },
  {
    path: '/customer-service/order/2',
    exact: true,
    name: 'Customer Orders',
    component: React.lazy(() => import('./App/pages/CustomerServiceOrderForm2')),
  },
  {
    path: '/customer-service/warehouse-orders',
    exact: true,
    name: 'Warehouse Orders',
    component: React.lazy(() => import('./App/pages/CustomerServiceWarehouseOrders')),
  },
  {
    path: '/customer-service/warehouse-order',
    exact: true,
    name: 'Warehouse Order',
    component: React.lazy(() => import('./App/pages/CreateNewWarehouseOrderPage')),
  },

  { path: '/warehouse/orders', exact: true, name: 'Orders', component: React.lazy(() => import('./App/pages/WarehouseOrderPage')) },
  {
    path: '/warehouse/record',
    exact: true,
    name: 'Order Record',
    component: React.lazy(() => import('./App/pages/WarehouseOrderRecordPage')),
  },

  {
    path: '/operator/airline-pricing',
    exact: true,
    name: 'Airline Pricing',
    component: React.lazy(() => import('./App/pages/OperationAirlinePricingPage')),
  },
  {
    path: '/operator/customer-management',
    exact: true,
    name: 'Customer Management',
    component: React.lazy(() => import('./App/pages/CustomerManagementPage')),
  },
  {
    path: '/operator/warehouse-orders',
    exact: true,
    name: 'Warehouse Orders',
    component: React.lazy(() => import('./App/pages/OperationWarehouseOrdersPage')),
  },
  {
    path: '/operator/warehouse-order',
    exact: true,
    name: 'Warehouse Order',
    component: React.lazy(() => import('./App/pages/CreateNewWarehouseOrderPage')),
  },
  {
    path: '/operator/orders',
    exact: true,
    name: 'Customer Orders',
    component: React.lazy(() => import('./App/pages/OperationCustomerOrdersPage')),
  },
  {
    path: '/operator/order/:id',
    exact: true,
    name: 'Customer Orders',
    component: React.lazy(() => import('./App/pages/CustomerService/OrderForm/OrderForm')),
  },
  // {
  //   path: '/operator/order/2',
  //   exact: true,
  //   name: 'Customer Orders',
  //   component: React.lazy(() => import('./App/pages/CustomerServiceOrderForm2')),
  // },
  {
    path: '/operator/customer-info/:id',
    exact: true,
    name: 'Customer Info',
    component: React.lazy(() => import('./App/pages/OperationCustomerManagementPage')),
  },
  {
    path: '/operator/customer-info',
    exact: true,
    name: 'Customer Info',
    component: React.lazy(() => import('./App/pages/OperationCustomerManagementPage')),
  },
  {
    path: '/operator/mawb-pool',
    exact: true,
    name: 'MAWB Pool',
    component: React.lazy(() => import('./App/pages/OperationMAWBPool')),
  },
  {
    path: '/operator/statistics',
    exact: true,
    name: 'Statistics',
    component: React.lazy(() => import('./App/pages/Statistics')),
  },
  {
    path: '/operator/address-management',
    exact: true,
    name: 'Address Management',
    component: React.lazy(() => import('./App/pages/AddressManagementPage')),
  },

  {
    path: '/bushiness-manager/airline-pricing',
    exact: true,
    name: 'Business Status',
    component: React.lazy(() => import('./App/pages/BusinessManagerBusinessStatusPage')),
  },
  {
    path: '/bushiness-manager/customer-management',
    exact: true,
    name: 'Staff Role Assignment',
    component: React.lazy(() => import('./App/pages/BusinessManagerStaffRoleAssignmentPage')),
  },

  {
    path: '/accounting/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() => import('./App/pages/AccountingDashboard')),
  },
  {
    path: '/accounting/debit-note',
    exact: true,
    name: 'Debit Note',
    component: React.lazy(() => import('./App/pages/AccountingDebitNote')),
  },
  {
    path: '/accounting/debit-note/create',
    exact: true,
    name: 'Create Debit Note',
    component: React.lazy(() => import('./App/pages/AccountingCreateDebitNote')),
  },
  {
    path: '/accounting/debit-note/create/:id',
    exact: true,
    name: 'Create Debit Note',
    component: React.lazy(() => import('./App/pages/AccountingCreateDebitNote')),
  },
  {
    path: '/accounting/old-credit-note',
    exact: true,
    name: 'Credit Note',
    component: React.lazy(() => import('./App/pages/AccountingCreditNote_demo')),
  },
  {
    path: '/accounting/old-credit-note/create',
    exact: true,
    name: 'Create Credit Note',
    component: React.lazy(() => import('./App/pages/AccountingCreateCreditNote_demo')),
  },
  {
    path: '/accounting/old-credit-note/insert',
    exact: true,
    name: 'Insert Credit Note',
    component: React.lazy(() => import('./App/pages/AccountingInsertCreditNote_demo')),
  },

  {
    path: '/accounting/credit-note',
    exact: true,
    name: 'Credit Note',
    component: React.lazy(() => import('./App/pages/AccountingCreditNote')),
  },
  {
    path: '/accounting/credit-note/create',
    exact: true,
    name: 'Create Credit Note',
    component: React.lazy(() => import('./App/pages/AccountingCreateCreditNote')),
  },
  {
    path: '/accounting/credit-note/create/:id',
    exact: true,
    name: 'Create Credit Note',
    component: React.lazy(() => import('./App/pages/AccountingCreateCreditNote')),
  },
  {
    path: '/accounting/costing',
    exact: true,
    name: 'Costing',
    component: React.lazy(() => import('./App/pages/AccountingCosting')),
  },
  {
    path: '/accounting/costing/create',
    exact: true,
    name: 'Create Costing',
    component: React.lazy(() => import('./App/pages/AccountingCreateCosting')),
  },
  {
    path: '/accounting/costing/create/:id',
    exact: true,
    name: 'Edit Costing',
    component: React.lazy(() => import('./App/pages/AccountingCreateCosting')),
  },

  {
    path: '/accounting/statement',
    exact: true,
    name: 'Statement',
    component: React.lazy(() => import('./App/pages/AccountingStatement')),
  },
  {
    path: '/accounting/statement/create',
    exact: true,
    name: 'Create Costing',
    component: React.lazy(() => import('./App/pages/AccountingCreateStatement')),
  },
  // {
  //   path: '/accounting/send-statement',
  //   exact: true,
  //   name: 'Send Statement',
  //   component: React.lazy(() => import('./App/pages/AccountingSendStatement')),
  // },
  {
    path: '/accounting/charge-table',
    exact: true,
    name: 'Charge Table',
    component: React.lazy(() => import('./App/pages/ChargeTable')),
  },
  {
    path: '/accounting/charge-table/create',
    exact: true,
    name: 'Create Charge',
    component: React.lazy(() => import('./App/pages/ChargeCreate')),
  },
  {
    path: '/accounting/create-invoice',
    exact: true,
    name: 'Create Invoice',
    component: React.lazy(() => import('./App/pages/InvoiceCreate')),
  },
  {
    path: '/accounting/create-invoice/:id',
    exact: true,
    name: 'Edit Invoice',
    component: React.lazy(() => import('./App/pages/InvoiceCreate')),
  },
  {
    path: '/accounting/invoice-listing',
    exact: true,
    name: 'Invoices',
    component: React.lazy(() => import('./App/pages/InvoiceListing')),
  },
  {
    path: '/accounting/view-invoices',
    exact: true,
    name: 'Statement Download',
    component: React.lazy(() => import('./App/pages/ViewInvoices')),
  },
  {
    path: '/accounting/general-ledger-types',
    exact: true,
    name: 'Ledger Types',
    component: React.lazy(() => import('./App/pages/ledgerTypeList')),
  },
  {
    path: '/accounting/general-ledger-types/:id',
    exact: true,
    name: 'Ledger Types Edit',
    component: React.lazy(() => import('./App/pages/ledgerTypeEdit')),
  },
  {
    path: '/accounting/general-ledger',
    exact: true,
    name: 'General Ledger',
    component: React.lazy(() => import('./App/pages/generalLedgerList')),
  },
  {
    path: '/accounting/general-ledger/:id',
    exact: true,
    name: 'Bank Entry',
    component: React.lazy(() => import('./App/pages/generalLedgerEdit')),
  },
  {
    path: '/accounting/bank-entry',
    exact: true,
    name: 'Bank Entry',
    component: React.lazy(() => import('./App/pages/bankEntryList')),
  },
  {
    path: '/accounting/bank-entry/:id',
    exact: true,
    name: 'Bank Entry',
    component: React.lazy(() => import('./App/pages/bankEntryEdit')),
  },
  {
    path: '/accounting/bank-settlement',
    exact: true,
    name: 'Bank Settlement',
    component: React.lazy(() => import('./App/pages/bankSettlementList')),
  },
  {
    path: '/accounting/bank-settlement/:id',
    exact: true,
    name: 'Bank Settlement',
    component: React.lazy(() => import('./App/pages/bankSettlementEdit')),
  },

  /*
          {
          id: 'invoice-listing',
          title: 'Invoice',
          type: 'item',
          url: '/accounting/invoice-listing',
          icon: 'fa-solid fa-file-invoice-dollar',
        },
        */
];

// below routes will be render with AdminLayout
export const customerRoutes: RouteObject[] = [
  // { path: '/customer-sample', exact: true, name: 'Sample Page', component: React.lazy(() => import('./Demo/Other/SamplePage')) },
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: React.lazy(() => import('./App/pages/CustomerDashboardPage')),
  },
  { path: '/orders', exact: true, name: 'Orders', component: React.lazy(() => import('./App/pages/CustomerOrdersPage')) },
  { path: '/check-rates', exact: true, name: 'Check Rates', component: React.lazy(() => import('./App/pages/CheckRatesPage')) },
  { path: '/pre-order/1', exact: true, name: 'Pre Order', component: React.lazy(() => import('./App/pages/PreOrderPage_1')) },
  { path: '/pre-order/2', exact: true, name: 'Pre Order', component: React.lazy(() => import('./App/pages/PreOrderPage_2')) },
  {
    path: '/pre-order/double-check',
    exact: true,
    name: 'Pre Order',
    component: React.lazy(() => import('./App/pages/PreOrderPage_DoubleCheck')),
  },
  { path: '/pre-order/finished', exact: true, name: 'Pre Order', component: React.lazy(() => import('./App/pages/PreOrderPage_Finished')) },
];

export const publicRoutes: RouteObject[] = [
  { path: '/', exact: true, name: 'Signin', component: React.lazy(() => import('./App/pages/SignIn')) },
];
// export default routes;
