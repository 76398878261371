import * as React from 'react';
import { lazy, Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import { customerRoutes, publicRoutes, staffRoutes } from '../routes';

const AdminLayout = lazy(() => import('./layout/AdminLayout'));
const App: React.FC = () => {
  const location = useLocation();
  return (
    <>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Route path={publicRoutes.map((x) => x.path)}>
            <Switch location={location} key={location.pathname}>
              {publicRoutes.map((route, index) => {
                return route.component ? (
                  <Route key={index} path={route.path} exact={route.exact} render={(props: any) => <route.component {...props} />} />
                ) : null;
              })}
            </Switch>
          </Route>
          <Route path={staffRoutes.map((x) => x.path)}>
            <AdminLayout menus={staffRoutes} />
          </Route>
          <Route path={customerRoutes.map((x) => x.path)}>
            <AdminLayout menus={customerRoutes} />
          </Route>
        </Suspense>
      </ScrollToTop>
      <div className='backdrop' />
    </>
  );
};
export default App;
